<template>
  <v-card>
    <v-card-text v-if="result" class="pa-1 text-center">
      {{ result }}
    </v-card-text>
    <v-card-text class="pa-0">
      <qrcode-stream
        :camera="camera"
        :track="paintOutline"
        @decode="onDecode"
        @init="onInit"
      >
        <div v-show="showScanConfirmation" class="scan-confirmation">
          <v-icon color="success">mdi-check-circle</v-icon>
        </div>
      </qrcode-stream>
    </v-card-text>
    <v-card-actions v-if="!autoClose" class="pa-1">
      <v-btn
        small
        color="success"
        :disabled="isCanScan"
        @click="allowScan"
        block
      >
        {{ $t("labels.scan_next") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "QRCodeScanner",
  components: {
    QrcodeStream,
  },
  props: {
    name: {
      type: String,
      default: () => null,
    },
    autoClose: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    result: null,
    camera: "auto",
    showScanConfirmation: false,
    isCanScan: true,
  }),
  methods: {
    unpause() {
      this.camera = "auto";
    },
    pause() {
      this.camera = "off";
    },
    allowScan() {
      this.isCanScan = true;
      this.result = null;
      this.unpause();
    },
    disallowScan() {
      this.pause();
      this.isCanScan = false;
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.showScanConfirmation = this.camera === "off";
      }
    },

    async onDecode(content) {
      this.result = content;
      this.$emit("onScanned", {
        name: this.name,
        value: content,
      });
      if (this.autoClose) {
        this.close();
        return false;
      }
      this.disallowScan();
      /*this.pause()
      await this.timeout(500)
      this.unpause()*/
    },

    close() {
      this.$emit("close");
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
  },
};
</script>

<style scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
</style>
